import React, { useState, useEffect, Fragment } from 'react';
import Envelope from '../../../images/envelope-30x30.png';
import moment from 'moment-timezone';
import axios from 'axios';
const get = axios.get;

const InquiryRow = ({ inquiry }) => {
  const {
    email_subject,
    sent_at,
    email_from,
    email_to,
    formatted_message_text,
  } = inquiry.attributes;
  const from_email = email_from[0]['email'];
  const from_name = email_from[0]['name'];
  const to_email = email_to[0]['email'];
  const to_name = email_to[0]['name'];
  const mailto = `mailto:${from_email}?subject=${email_subject}`;
  const [expanded, setExpand] = useState(false);
  const toggleExpand = () => setExpand((prev) => !prev);
  return (
    <Fragment>
      <tr onClick={toggleExpand} style={{ cursor: 'pointer' }}>
        <td style={{ width: '5rem' }}>
          <a
            className={
              'text-teal-light ' + (expanded ? 'inquiry-hide' : 'inquiry-show')
            }
          >
            {expanded ? 'Hide' : 'View'}
          </a>
        </td>
        <td>{from_name}</td>
        <td>{from_email}</td>
        <td>{moment(sent_at).format('MMM DD, YYYY')}</td>
        <td>{email_subject}</td>
        <td style={{ display: 'table-cell' }}>&nbsp;</td>
      </tr>
      <tr style={{ display: expanded ? 'table-row' : 'none' }}>
        <td colSpan="6">
          <div className="inquiryMessageHeader">
            <strong>Date:</strong>{' '}
            {moment(sent_at).format('MMMM DD, YYYY - LT')}
            <br />
            <strong>From:</strong> {from_name}{' '}
            <a
              className="text-teal-light hover:text-teal no-underline"
              href={mailto}
              target="_blank"
            >
              &lt;{from_email}&gt;
            </a>
            <br />
            <strong>To:</strong> {to_name} &lt;{to_email}&gt;
            <br />
            <strong>Subject:</strong> {email_subject}
            <br />
          </div>
          <div
            className="inquiryMessageText"
            dangerouslySetInnerHTML={{ __html: formatted_message_text }}
          ></div>
          <div className="mt-2 mb-8">
            <a
              className="text-teal-light hover:text-teal no-underline font-bold"
              href={mailto}
              target="_blank"
            >
              Reply to {from_name}
            </a>
          </div>
        </td>
      </tr>
    </Fragment>
  );
};

const ReceivedInquiries = ({ listingId, closeModal, user }) => {
  const [status, setStatus] = useState(null);
  const [listing, setListing] = useState({});
  const [messages, setMessages] = useState([]);
  const [errorMsg, setErrorMsg] = useState(null);

  useEffect(() => {
    setStatus('loading');
    const fetchListing = async () => {
      const result = await get('/account/listing/' + listingId + '.json');
      setListing(result.data);
      setMessages(result.data.included);
      setStatus('loaded');
    };
    if (listingId) {
      fetchListing();
    }
  }, [listingId]);

  const selectComponent = () => {
    switch (status) {
      case 'loaded':
        return (
          <div className="text-grey-darkest">
            <p className="leading-normal text-lg">
              Here are all of the inquiries you've received for this listing.
              You can reply to inquiries via your personal email. All inquiries
              are forwarded to
              <strong> {listing.data.attributes.email_address}</strong>. Any
              email attachments such as files, photos, etc. can be seen through
              your personal inbox for{' '}
              <strong> {listing.data.attributes.email_address}</strong>.
            </p>
            <hr className="border-b" />
            <h3 className="text-xl font-normal my-4">
              <strong>{messages.length} Inquiries - </strong>
              {listing.data.attributes.headline}
            </h3>
            <div className="rounded-table-container">
              <table className="inquiriesTable">
                <thead>
                  <tr>
                    <th>
                      <img style={{ marginBottom: '-0.5rem' }} src={Envelope} />
                    </th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Date</th>
                    <th>Subject</th>
                    <th style={{ display: 'table-cell' }}>&nbsp;</th>
                  </tr>
                </thead>
                <tbody className="doublerow doublerow__expandable">
                  {messages &&
                    messages.map((message) => (
                      <InquiryRow key={message.id} inquiry={message} />
                    ))}
                </tbody>
              </table>
            </div>
            <a
              className="text-lg no-underline text-teal-light hover:text-teal"
              href={`/account/listing/${listing.data.id}/inquiries_dl`}
              targe="_blank"
            >
              Download all messages as CSV
            </a>
          </div>
        );
      case 'error':
        return <Fragment>Error</Fragment>;
      case 'loading':
      default:
        return <span>Loading Inquiries...</span>;
    }
  };
  return <Fragment>{selectComponent()}</Fragment>;
};

export default ReceivedInquiries;

import React from 'react'

const CustomCheckbox = ({ name, label, checked, onChange, error, labelClass, ...props }) => {
  return (
    <div className={'customCheckbox ' + (error ? 'customCheckboxError' : '')}>
      <input
        type='checkbox'
        id={ name }
        name={ name }
        checked={checked}
        onChange={onChange}
        {...props}
      />
      <label className={labelClass || 'customCheckboxLabel'} htmlFor={ name }>{ label }</label>
      {error && <p className='mt-2 text-red text-sm'>{error}</p>}
    </div>
  )
}

export default CustomCheckbox

import React, { useState, Fragment, useRef } from 'react';
import { toggleNotifyRepost } from 'shared/helpers';
import { Tooltip } from 'react-tooltip';
import NotifyOn from '../../assets/images/account/notify-on.svg';
import NotifyOff from '../../assets/images/account/notify-off.svg';

const NotifyListing = ({ listing_id, bookmarked, notifyrepost, user_id }) => {
  const [notify, setNotify] = useState(notifyrepost);
  const [alreadySaved, setAlreadySaved] = useState(
    bookmarked.includes(parseInt(listing_id)),
  );

  const handleToggleNotify = async (
    listing_id,
    user_id,
    alreadySaved,
    notifyrepost,
  ) => {
    toggleNotifyRepost(listing_id, user_id);
    setNotify(!notify);
  };
  const tooltipRef = useRef(null);

  return (
    <span className="bookmarkTooltipContainer">
      <div
        id={`togglenotify-${listing_id}`}
        className="expiredListingButton expiredListingButton__notify"
        onClick={(e) => {
          e.preventDefault();
          handleToggleNotify(listing_id, user_id, alreadySaved, notify);
        }}
      >
        {notify ? (
          <Fragment>
            <img className="block" src={NotifyOn} style={{ height: '20px' }} />
            <div>Do Not Notify Me</div>
          </Fragment>
        ) : (
          <Fragment>
            <img className="block" src={NotifyOff} style={{ height: '20px' }} />
            <div>Notify Me</div>
          </Fragment>
        )}
      </div>
      <Tooltip
        ref={tooltipRef}
        anchorSelect={`#togglenotify-${listing_id}`}
        place="bottom"
        openOnClick="true"
        border="1px solid #4f4f4f"
        className="bookmarkTooltip"
      >
        <p className="leading-tight">
          You will {notify ? '' : 'no longer '} be notified if this listing is
          reposted.
        </p>
      </Tooltip>
    </span>
  );
};

export default NotifyListing;

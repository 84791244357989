import React, { Fragment } from 'react';
import CustomCheckbox from 'homepage_components/subscribe_form/custom_checkbox';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { validateEmail } from 'shared/helpers';

const SubscribeEmailOnly = ({ email, handleEmailSubmit }) => (
  <Formik
    initialValues={{
      email: email,
    }}
    validate={({ email }) => validateEmail({ email })}
    onSubmit={(values, { setSubmitting }) => {
      handleEmailSubmit(values);
      setSubmitting(false);
    }}
  >
    {({ isSubmitting, errors, touched }) => (
      <Fragment>
        <p className="font-bold text-md mb-6">
          You’ll get new listings delivered to your inbox every Wednesday.
        </p>
        <Form>
          <div className="mb-4 max-w-sm">
            <label
              htmlFor="email"
              className="block font-bold mb-2 text-grey-dark"
            >
              Email
            </label>
            <Field
              type="email"
              name="email"
              className={
                'rounded-lg p-3 w-full ' +
                (errors.email ? 'border-2 border-red' : 'border')
              }
            />
            {errors.email && touched.email && (
              <div className="text-red mt-2">{errors.email}</div>
            )}
            <p className="mt-4 mb-6 leading-lax text-xs">
              We’ll also send you our monthly newsletter with{' '}
              <a
                className="text-teal hover:text-teal-light no-underline"
                href="/resources"
                target="_blank"
              >
                articles
              </a>{' '}
              about{' '}
              <a
                className="text-teal hover:text-teal-light no-underline"
                href="/resources/section/real-estate"
                target="_blank"
              >
                real estate
              </a>
              ,{' '}
              <a
                className="text-teal hover:text-teal-light no-underline"
                href="/resources/section/jobs-gigs"
                target="_blank"
              >
                jobs
              </a>
              ,{' '}
              <a
                className="text-teal hover:text-teal-light no-underline"
                href="/resources/section/art-and-artists"
                target="_blank"
              >
                artists
              </a>
              , and occasional sponsored emails.
            </p>
          </div>
          <div className="max-w-xs">
            <button
              className="aBtn btn-orange inline-block"
              type="submit"
              disabled={isSubmitting}
            >
              Next &gt;
            </button>
          </div>
        </Form>
      </Fragment>
    )}
  </Formik>
);

export default SubscribeEmailOnly;

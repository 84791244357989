import React, { useState, forwardRef } from 'react';
import Modal from 'react-modal';
import RemoveListing from 'components/account/account_modal/remove_listing';
import HideListing from 'components/account/account_modal/hide_listing';
import ShareLink from 'components/account/account_modal/share_link';
import DeleteDraft from 'components/account/account_modal/delete_draft';
import SupportTicket from 'components/account/account_modal/support_ticket';
import ReceivedInquiries from 'components/account/account_modal/received_inquiries';
import Message from 'components/account/account_modal/message';

import { formatDate } from 'admin_components/admin_helpers';

const AccountModal = forwardRef(({ modalStyles, closeButtonStyles }, ref) => {
  const [isOpen, setOpen] = useState(false);
  const [action, setAction] = useState(null);
  const [listingId, setListingId] = useState(null);
  const [emailDate, setEmailDate] = useState(null);
  const [user, setUser] = useState(null);
  const [message, setMessage] = useState('');
  const [closeAction, setCloseAction] = useState('normal'); // ['normal', 'reload']

  const resetComponent = () => {
    setAction(null);
    setOpen(false);
    setListingId(null);
    setEmailDate(null);
    setCloseAction('normal');
    setMessage('');
  };
  const closeModal = () => {
    if (closeAction === 'reload') {
      window.location = '/account/listings';
    } else {
      resetComponent();
    }
  };
  ref.current = (action, listingId, emailDate, user, message) => {
    setAction(action);
    setListingId(listingId);
    setEmailDate(formatDate(emailDate));
    setUser(user);
    setMessage(message);
    setOpen(true);
  };
  const selectContent = () => {
    switch (action) {
      case 'remove-submitted':
      case 'remove-live':
        return (
          <RemoveListing
            listingId={listingId}
            action={action}
            emailDate={emailDate}
            closeModal={closeModal}
            setCloseAction={setCloseAction}
          />
        );
      case 'hide':
        return (
          <HideListing
            listingId={listingId}
            action={action}
            emailDate={emailDate}
            closeModal={closeModal}
            setCloseAction={setCloseAction}
          />
        );
      case 'share':
        return <ShareLink listingId={listingId} closeModal={closeModal} />;
      case 'delete-draft':
        return <DeleteDraft closeModal={closeModal} />;
      case 'support-ticket':
        return (
          <SupportTicket
            listingId={listingId}
            user={user}
            closeModal={closeModal}
          />
        );
      case 'received-inquiries':
        return (
          <ReceivedInquiries
            listingId={listingId}
            user={user}
            closeModal={closeModal}
          />
        );
      case 'message':
        return <Message message={message} closeModal={closeModal} />;
    }
  };
  return (
    <Modal style={modalStyles} isOpen={isOpen} onRequestClose={closeModal}>
      <div style={{ marginTop: '1rem', padding: '1rem', fontSize: '1.3em' }}>
        {selectContent()}
      </div>
      <button
        style={closeButtonStyles}
        aria-label="Close modal"
        onClick={closeModal}
      >
        &times;
      </button>
    </Modal>
  );
});

export default AccountModal;

import React, { useState, Fragment } from 'react';
import { saveBookmark, removeBookmark } from 'shared/helpers';
import HeartOn from '../../assets/images/account/save-on.svg';
import HeartOff from '../../assets/images/account/save-off.svg';
import { Tooltip } from 'react-tooltip';

const BookmarkListing = ({
  listing_id,
  bookmarked,
  user_id,
  is_supporting,
}) => {
  const [alreadySaved, setAlreadySaved] = useState(
    bookmarked.includes(parseInt(listing_id)),
  );

  const handleToggleBookmark = async (listing_id, user_id, alreadySaved) => {
    if (alreadySaved) {
      await removeBookmark(listing_id, user_id);
    } else {
      await saveBookmark(listing_id, user_id);
    }
    setAlreadySaved(!alreadySaved);
  };

  return (
    <span className="bookmarkTooltipContainer">
      <div
        id={`togglebookmark-${listing_id}`}
        className="saveListingButton "
        onClick={(e) => {
          e.preventDefault();
          if (is_supporting) {
            handleToggleBookmark(listing_id, user_id, alreadySaved);
          }
        }}
      >
        {alreadySaved ? (
          <Fragment>
            <img className="block" src={HeartOn} style={{ height: '20px' }} />
            <div>Saved</div>
          </Fragment>
        ) : (
          <Fragment>
            <img className="block" src={HeartOff} style={{ height: '20px' }} />
            <div>Save this Listing</div>
          </Fragment>
        )}
      </div>
      {!is_supporting && (
        <Tooltip
          anchorSelect={`#togglebookmark-${listing_id}`}
          place="bottom"
          openOnClick="true"
          clickable="true"
          border="1px solid #4f4f4f"
          className="bookmarkTooltip"
        >
          <Fragment>
            <p className="mb-4 leading-tight">
              {user_id && (
                <Fragment>
                  <a
                    className="cursor-pointer text-teal-light hover:text-teal"
                    href={`/membership?wants_to=view_listing-${listing_id}`}
                    target="_blank"
                  >
                    Upgrade
                  </a>{' '}
                  to a Supporting Membership
                </Fragment>
              )}
              {!user_id && (
                <Fragment>
                  <a
                    className="cursor-pointer text-teal-light hover:text-teal"
                    href="/signin"
                    target="_blank"
                  >
                    Login
                  </a>{' '}
                  and become a Supporting Member
                </Fragment>
              )}{' '}
              to save your favorite listings!
            </p>
            <p>
              <a
                className="cursor-pointer text-teal-light hover:text-teal no-underline"
                href={`/membership?wants_to=view_listing-${listing_id}`}
                target="_blank"
              >
                Learn more here{' '}
                <span
                  className="icon-arrow-right font-normal"
                  style={{ fontSize: '12px' }}
                ></span>
              </a>
            </p>
          </Fragment>
        </Tooltip>
      )}
    </span>
  );
};

export default BookmarkListing;

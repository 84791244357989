import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['searchbutton'];
  toggleclear() {
    this.searchbuttonTarget.classList.replace(
      'search-button__clear',
      'search-button__search',
    );
  }
}

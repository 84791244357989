import React, { Fragment, useState } from 'react';

const ClipboardLink = ({ linkUrl, linkText, confirmationText, variant }) => {
  const [copied, setCopied] = useState(false);
  const className =
    variant && variant == 'admin' ? 'button' : 'article-clipboard-link';
  return (
    <Fragment>
      <a
        className={className}
        title="Click to copy URL to your clipboard"
        href={linkUrl}
        onClick={(e) => {
          e.preventDefault();
          navigator.clipboard.writeText(e.target.href).then(
            () => {
              setCopied(true);
            },
            () => {
              e.stopPropagation();
              /* error */
            },
          );
        }}
      >
        {linkText}
      </a>
      {copied && (
        <span>
          <em>&nbsp;{confirmationText}</em>
        </span>
      )}
    </Fragment>
  );
};

export default ClipboardLink;

import React, { useRef } from 'react';
import ContactOn from '../../../assets/images/account/contact-on.svg';
import { Tooltip } from 'react-tooltip';

const ContactedIndicator = ({ listing, contactedon }) => {
  const contactedTooltipRef = useRef(null);
  return (
    <span className="bookmarkTooltipContainer">
      <img
        id={`contacted-${listing.id}`}
        src={ContactOn}
        className="block"
        style={{ height: '15px' }}
        onClick={(e) => {
          e.preventDefault();
        }}
      />
      <Tooltip
        ref={contactedTooltipRef}
        anchorSelect={`#contacted-${listing.id}`}
        place="bottom"
        openOnClick="true"
        border="1px solid #4f4f4f"
        className="bookmarkTooltip"
      >
        <p className="leading-tight">
          You sent an email to this lister regarding this listing on{' '}
          {contactedon}
        </p>
      </Tooltip>
    </span>
  );
};

export default ContactedIndicator;

import React, { useState, useEffect, Fragment } from 'react';
import SubscribeEmailOnly from 'homepage_components/subscribe_form/subscribe_email_only';
import { xhrConfig } from 'form_components/apis/api';
import axios from 'axios';
const get = axios.get;
const post = axios.post;

const createSubscriber = async (subscriber) => {
  return post(
    '/subscribe/create_api.json',
    { subscriber: subscriber },
    xhrConfig(),
  );
};

const checkSubscriber = async (email) => {
  return get('/subscribe/check_api.json?email=' + email, xhrConfig());
};

const SubscribeForm = ({ acceptedCookies }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  const [email, setEmail] = useState('');
  const [errorText, setErrorText] = useState(null);

  const handleSubscribeSubmit = async (email) => {
    setErrorText(null);
    const response = await createSubscriber({
      email: email,
      blog_opt_in: '1',
      sponsor_opt_in: '1',
      listings_opt_in: '1',
      accept_terms: '1',
    });
    if (response.data.error) {
      setErrorText(response.data.error);
    } else {
      window.location = '/listings?status=subscribed';
    }
  };
  const handleEmailSubmit = async ({ email }) => {
    setEmail(email);
    const response = await checkSubscriber(email);
    if (response.data.subscribed) {
      window.location = '/listings?status=alreadySubscribed';
    } else {
      handleSubscribeSubmit(email);
    }
  };
  return (
    <div className="mt-2">
      <SubscribeEmailOnly {...{ email, handleEmailSubmit }} />
      {errorText && <div className="text-red mt-2">{errorText}</div>}
    </div>
  );
};

export default SubscribeForm;

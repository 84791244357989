import React, { Fragment } from 'react';
import { Formik, Form, Field } from 'formik';
import CustomCheckbox from 'form_components/fields/custom_checkbox';

const Terms = ({
  acceptedTerms,
  setAcceptedTerms,
  handleTermsSubmit,
  children,
}) => {
  return (
    <Fragment>
      <h2 className="font-bold text-2xl mb-2 ">
        Policies & Community Agreements
      </h2>
      <p className="mb-4 leading-tight">
        Listings Project is built on a vision of accountability to our
        community. We expect all Listers in our community to hold themselves
        accountable to our{' '}
        <a className="text-teal-light hover:text-teal" href="" target="_blank">
          Community Agreements
        </a>
        , which includes:
      </p>

      <ul className="mb-4  leading-normal">
        <li>
          <strong>Equity.</strong> Provide equal access to opportunities and
          resources
        </li>
        <li>
          <strong></strong>Transparency. Communicate clearly, openly, and
          honestly
        </li>
        <li>
          <strong>Accountability.</strong> Take responsibility for actions,
          behaviors, language and impact
        </li>
        <li>
          <strong>Collective Care.</strong> Take care of yourself so you can
          take care of and be of service to your community
        </li>
        <li>
          <strong>Justice.</strong> Challenge oppression perpetuated by power
          inequality in society
        </li>
        <li>
          <strong>Joy.</strong> Be open to surprises and delights in every
          interaction
        </li>
      </ul>

      <Formik
        initialValues={{
          acceptedTerms: acceptedTerms,
        }}
        validate={({ acceptedTerms }) => {
          let errors = {};
          if (!acceptedTerms) {
            errors.acceptedTerms = 'You must accept terms to join.';
          }
          return errors;
        }}
        onSubmit={async (values, { setSubmitting }) => {
          handleTermsSubmit(values);
          setSubmitting(false);
        }}
      >
        {({ isSubmitting, errors, touched }) => (
          <Fragment>
            <Form>
              <div className="mb-4 max-w-sm">
                <Field
                  name="acceptedTerms"
                  component={CustomCheckbox}
                  checked={acceptedTerms}
                  handleChange={(e, form, field) => {
                    form.setFieldValue(field.name, e.currentTarget.checked);
                    setAcceptedTerms(e.currentTarget.checked);
                  }}
                  label={
                    <Fragment>
                      I have read and accept the{' '}
                      <a
                        href="/terms-of-use"
                        className="text-teal-light hover:text-teal"
                        target="_blank"
                      >
                        Terms of Use
                      </a>
                      ,{' '}
                      <a
                        href="/privacy-policy"
                        className="text-teal-light hover:text-teal"
                        target="_blank"
                      >
                        Privacy Policy
                      </a>
                      ,{' '}
                      <a
                        href="/membership-terms-and-conditions"
                        className="text-teal-light hover:text-teal"
                        target="_blank"
                      >
                        Membership Terms & Conditions
                      </a>
                      , and{' '}
                      <a
                        href="/refund-policy"
                        className="text-teal-light hover:text-teal"
                        target="_blank"
                      >
                        Refund Policy
                      </a>
                      .
                    </Fragment>
                  }
                />
                {errors.acceptedTerms && touched.acceptedTerms && (
                  <div className="text-red mt-2">{errors.acceptedTerms}</div>
                )}
              </div>
              <div className="">
                <button
                  className="btn btn-orange inline-block"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Next &gt;
                </button>
              </div>
            </Form>
          </Fragment>
        )}
      </Formik>
      {children}
    </Fragment>
  );
};

export default Terms;

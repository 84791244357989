require('controllers');
import '../css/tailwind.css';
import '../css/homepage.scss';
import loadStripe from 'shared/stripe-loader.js';
import { setupNav } from 'shared/helpers';
import 'formdata-polyfill';
import React, { createRef } from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import SubscribeForm from 'homepage_components/subscribe_form/subscribe_form';
import JoinForm from 'homepage_components/join_form/join_form';
import MembershipSelection from 'homepage_components/membership_selection/membership_selection';
import CookieAcceptanceTroubleshooter from 'components/cookies_notice/troubleshooter';
import CookieNotice from 'components/cookies_notice/notice';
import Cookies from 'js-cookie';
import Modal from 'react-modal';
import AccountModal from 'components/account/account_modal';
import SupportTicket from 'components/account/account_modal/support_ticket';
import ReceivedInquiries from 'components/account/account_modal/received_inquiries';
import ClipboardLink from 'homepage_components/clipboard_link';
import ClipboardButton from 'homepage_components/clipboard_button';
import BookmarkListing from 'homepage_components/bookmark_listing';
import NotifyListing from 'homepage_components/notify_listing';
import SavedListingCard from 'homepage_components/saved_listing_card';
import Gallery from 'blueimp-gallery';

import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';

import 'shared/jquery-is_in_viewport';
import '@hotwired/turbo-rails';
Turbo.session.drive = false;

window.LPAcceptedCookies = true; // Cookies.get('accepted_cookies') === 'yes';
loadStripe();

var checkViewPhotosButton = function () {
  if ($('#lightbox-links').areElementsVisible('a')) {
    $('#view-photos-button').hide();
  } else {
    $('#view-photos-button').show();
  }
};

$(window).on('load', function () {
  checkViewPhotosButton();
});

window.showReceipt = function (id) {
  var description = '';
  var params =
    'status=no,location=no,toolbar=no,menubar=no,width=640,height=800';
  window.open('/account/listing/' + id + '/receipt', description, params);
};

document.addEventListener('DOMContentLoaded', () => {
  $(window).on('resize orientationChange', function (event) {
    checkViewPhotosButton();
  });
  // blueimp gallery lightbox
  $('#lightbox-links').on('click', function (event) {
    event = event || window.event;
    var target = event.target || event.srcElement,
      link = target.src ? target.parentNode : target,
      options = { index: link, event: event, transitionSpeed: true },
      links = this.getElementsByTagName('a');
    Gallery(links, options);
  });

  $('#view-photos-button').on('click', function (event) {
    event.preventDefault();
    Gallery($('#lightbox-links').find('a'), { index: 0 });
  });

  $('.services-view-photos-button').on('click', function (event) {
    event.preventDefault();
    Gallery($('#lightbox-links').find('a'), { index: 0 });
  });

  // blueimp gallery lightbox
  $('.gallery-link').on('click', function (event) {
    event.preventDefault();
    var uuid = $(this).data('listing-uuid');
    $.ajax('/listing_photos', {
      method: 'GET',
      data: { listing_uuid: uuid },
    }).done(function (data, status, jqxhr) {
      if (data.length) {
        var image_urls = Object.values(data).map((image) => image.cdn_url);
        Gallery(image_urls);
      }
    });
  });

  if (document.getElementById('subscribeFormRoot')) {
    ReactDOM.render(
      <SubscribeForm acceptedCookies={LPAcceptedCookies} />,
      document.getElementById('subscribeFormRoot'),
    );
  }
  const joinFormElement = document.getElementById('joinFormRoot');
  if (joinFormElement) {
    ReactDOM.render(
      <JoinForm
        initialWantsTo={joinFormElement.dataset.wantsto}
        initialEmail={joinFormElement.dataset.email}
      />,
      joinFormElement,
    );
  }

  const membershipSelectionElement = document.getElementById(
    'membershipSelectionRoot',
  );
  if (membershipSelectionElement) {
    ReactDOM.render(
      <MembershipSelection
        wantsTo={membershipSelectionElement.dataset.wantsto}
        email={membershipSelectionElement.dataset.email}
        userId={membershipSelectionElement.dataset.userid}
        subscribed={membershipSelectionElement.dataset.subscribed}
      />,
      membershipSelectionElement,
    );
  }

  if (document.getElementById('cookieAcceptanceTroubleshooterRoot')) {
    ReactDOM.render(
      <CookieAcceptanceTroubleshooter />,
      document.getElementById('cookieAcceptanceTroubleshooterRoot'),
    );
  }
  if (document.getElementById('cookieNoticeRoot')) {
    ReactDOM.render(
      <CookieNotice />,
      document.getElementById('cookieNoticeRoot'),
    );
  }

  Array.from(document.getElementsByClassName('clipboardLinkRoot')).forEach(
    (element, index) => {
      ReactDOM.render(
        <ClipboardLink
          key={`clipboardLink-${index}`}
          linkUrl={element.dataset.linkurl}
          linkText={element.dataset.linktext}
          confirmationText={element.dataset.confirmationtext}
        />,
        element,
      );
    },
  );
  Array.from(document.getElementsByClassName('clipboardButtonRoot')).forEach(
    (element, index) => {
      ReactDOM.render(
        <ClipboardButton
          key={`clipboardButton-${index}`}
          clipText={element.dataset.cliptext}
          clipExplanation={element.dataset.clipexplanation}
        />,
        element,
      );
    },
  );

  Array.from(document.getElementsByClassName('bookmarkListingRoot')).forEach(
    (element, index) => {
      const root = createRoot(element);
      root.render(
        <BookmarkListing
          key={`bookmarkListing-${index}`}
          listing_id={element.dataset.listingid}
          bookmarked={element.dataset.bookmarked}
          user_id={element.dataset.userid}
          is_supporting={element.dataset.issupporting == 'true'}
        />,
      );
    },
  );

  Array.from(document.getElementsByClassName('notifyListingRoot')).forEach(
    (element, index) => {
      const root = createRoot(element);
      root.render(
        <NotifyListing
          key={`bookmarkListing-${index}`}
          listing_id={element.dataset.listingid}
          bookmarked={element.dataset.bookmarked}
          notifyrepost={element.dataset.notifyrepost == 'true'}
          user_id={element.dataset.userid}
          is_supporting={element.dataset.issupporting == 'true'}
        />,
      );
    },
  );

  Array.from(document.getElementsByClassName('savedListingCardRoot')).forEach(
    (element, index) => {
      const root = createRoot(element);
      root.render(
        <SavedListingCard
          key={`removeBookmark-${index}`}
          listing={JSON.parse(element.dataset.listingjson)}
          user_id={element.dataset.userid}
          contacted={element.dataset.contacted == 'true'}
          contactedon={element.dataset.contactedon}
          notifyrepost={element.dataset.notifyrepost == 'true'}
          hideBookmarkControl={element.dataset.hidebookmarkcontrol == 'true'}
        />,
      );
    },
  );

  // MODAL STUFF
  const modalStyles = {
    overlay: {
      backgroundColor: 'rgba(79,79,79, 0.8)',
      zIndex: '2',
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      border: 'none',
      borderRadius: 'none',
      maxHeight: '95vh',
      maxWidth: '95%',
      fontSize: '16px',
    },
  };

  const closeButtonStyles = {
    position: 'absolute',
    right: '1rem',
    top: '1rem',
    background: 'transparent',
    border: 'none',
    fontSize: '2.5rem',
    lineHeight: '0.5',
    cursor: 'pointer',
  };

  if (document.getElementById('accountModalRoot')) {
    window.setAccountModalListingIdRef = createRef();
    ReactDOM.render(
      <AccountModal
        ref={window.setAccountModalListingIdRef}
        modalStyles={modalStyles}
        closeButtonStyles={closeButtonStyles}
      />,
      document.getElementById('accountModalRoot'),
    );
    $('.button-account').on('click', function (event) {
      Modal.setAppElement('#accountModalRoot');
      event.preventDefault();
      var listingId = $(this).data('listing-id');
      var emailDate = $(this).data('email-date');
      var action = $(this).data('action');
      var user = $(this).data('user');
      var message = $(this).data('message');
      // open support ticket links in new tab if viewport is small
      var vw = Math.max(
        document.documentElement.clientWidth,
        window.innerWidth || 0,
      );
      if (action === 'support-ticket' && vw < 500) {
        window.open('/account/listing/' + listingId + '/support_ticket/new');
      } else if (action === 'received-inquiries' && vw < 500) {
        window.open('/account/listing/' + listingId + '/inquiries');
      } else {
        window.setAccountModalListingIdRef.current(
          action,
          listingId,
          emailDate,
          user,
          message,
        );
      }
    });

    if (window.LPFlashMessage) {
      Modal.setAppElement('#accountModalRoot');
      window.setAccountModalListingIdRef.current(
        'message',
        null,
        null,
        null,
        window.LPFlashMessage,
      );
    }
  }

  function closeWindow() {
    if (window.opener === null) {
      window.location.href = '/account/listings';
    } else {
      window.close();
    }
  }

  // standalone support ticket form
  if (document.getElementById('supportTicketFormRoot')) {
    ReactDOM.render(
      <SupportTicket
        listingId={$('#supportTicketFormRoot').data('listing-id')}
        user={$('#supportTicketFormRoot').data('user')}
        closeModal={closeWindow}
      />,
      document.getElementById('supportTicketFormRoot'),
    );
  }

  // standalone listing inquiries
  if (document.getElementById('receivedInquiriesRoot')) {
    ReactDOM.render(
      <ReceivedInquiries
        listingId={$('#receivedInquiriesRoot').data('listing-id')}
        user={$('#receivedInquiriesRoot').data('user')}
        closeModal={closeWindow}
      />,
      document.getElementById('receivedInquiriesRoot'),
    );
  }
  // END MODAL STUFF
});

// // Swiper
const buildSwiperSlider = (sliderEl) => {
  const sliderId = sliderEl.dataset.id;
  return new Swiper(`.${sliderEl.dataset.id}`, {
    modules: [Navigation, Pagination],
    slidesPerView: 'auto',
    navigation: {
      nextEl: `.swiper-button-next-${sliderId}`,
      prevEl: `.swiper-button-prev-${sliderId}`,
    },
  });
};
document.addEventListener('DOMContentLoaded', () => {
  document
    .querySelectorAll('.homeCardSwiper')
    .forEach((slider) => buildSwiperSlider(slider));
});

// setup nav
setupNav();

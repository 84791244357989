import Cookies from 'js-cookie'

const loadStripe = () => {
  const acceptedCookies = true;
  //Cookies.get('accepted_cookies') === 'yes'
  if (acceptedCookies) {
    const script = document.createElement('script');
    script.src = 'https://js.stripe.com/v3/';
    document.head.appendChild(script);
  }
}

export default loadStripe

import React, { Fragment, useRef } from 'react';
import { Tooltip } from 'react-tooltip';
import { removeBookmark } from 'shared/helpers';
import HeartOn from '../../../assets/images/account/save-on.svg';

const BookmarkControl = ({ listing, user_id, setRemoved }) => {
  const tooltipRef = useRef(null);
  const handleRemoveBookmark = async (listing_id, user_id) => {
    await removeBookmark(listing_id, user_id);
    setRemoved(true);
  };
  return (
    <Fragment>
      <img
        id={`unbookmark-${listing.id}`}
        className="block unbookmark-listing"
        src={HeartOn}
        style={{ height: '20px' }}
        onClick={(e) => {
          e.preventDefault();
        }}
      />
      <span
        onClick={(e) => e.preventDefault()}
        style={{ cursor: 'default' }}
        className="bookmarkTooltipContainer"
      >
        <Tooltip
          ref={tooltipRef}
          anchorSelect={`#unbookmark-${listing.id}`}
          place="bottom"
          openOnClick="true"
          clickable="true"
          border="1px solid #4f4f4f"
          className="bookmarkTooltip"
        >
          <p className="mb-4 leading-tight">
            Are you sure you want to remove this listing from your saved
            listings?
            {listing.expired && (
              <Fragment>
                {' '}
                You won't be able to see or save this listing again.
              </Fragment>
            )}
          </p>
          <p
            className="cursor-pointer text-teal-light hover:text-teal mb-4"
            onClick={(e) => {
              e.preventDefault();
              handleRemoveBookmark(listing.id, user_id);
            }}
          >
            Yes, remove listing
          </p>
          <p
            className="cursor-pointer text-teal-light hover:text-teal"
            onClick={(e) => {
              e.preventDefault();
              tooltipRef.current?.close();
            }}
          >
            Nevermind, keep listing
          </p>
        </Tooltip>
      </span>
    </Fragment>
  );
};

export default BookmarkControl;

import React, { Fragment } from 'react'
import Cookies from 'js-cookie'

const CookieAcceptanceTroubleshooter = () => {

  const acknowlegedCookies = Cookies.get('acknowleged_cookies')

  const setAccepted = () => {
    Cookies.set('acknowleged_cookies', 'yes', { expires: 365 })
    location.reload()
  }
  const deleteAccepted = () => {
    Cookies.remove('acknowleged_cookies')
    location.reload()
  }

  return (
    <Fragment>
      <div className='mt-4'>
        <strong>acknowleged_cookies:</strong> {acknowlegedCookies ? 'yes' : 'no'}
      </div>
      <div className='mt-4'>
        {acknowlegedCookies ?
          <button  className='p-1 bg-red text-white' onClick={deleteAccepted}>Delete</button> :
          <button className='bg-teal text-white p-1' onClick={setAccepted}>Acknowledge</button>
        }
      </div>
    </Fragment>
  )
}

export default CookieAcceptanceTroubleshooter

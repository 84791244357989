import React, { useState, useEffect, Fragment } from 'react';
import { Formik, Form, Field } from 'formik';
import TextField from 'form_components/fields/text_field';
import ReadOnlyTextField from 'form_components/fields/read_only_text_field';
import TextareaField from 'form_components/fields/textarea_field';
import axios from 'axios';
const get = axios.get;
const put = axios.put;
import { xhrConfig } from 'form_components/apis/api';
import * as Yup from 'yup';
import FieldErrors from 'form_components/fields/field_errors';

const MessageSchema = Yup.object().shape({
  subject: Yup.string().required('Subject cannot be blank'),
  message: Yup.string().required('Message cannot be blank'),
});

const createTicket = async (data) =>
  put(`/account/support_ticket`, data, xhrConfig());

const SupportTicket = ({ listingId, closeModal, user }) => {
  const [status, setStatus] = useState(null);
  const [listing, setListing] = useState({});
  const [errorMsg, setErrorMsg] = useState(null);

  const sendMessage = async (values, actions) => {
    actions.setSubmitting(true);
    try {
      const _result = await createTicket({
        id: values.listingId,
        subject: values.subject,
        message: values.message,
      });
      actions.setSubmitting(false);
      actions.resetForm({values: values});
      setStatus('sent');
    } catch (error) {
      actions.setSubmitting(false);
      if (error.response && error.response.status === 400) {
        // fix rails-y error labeling to match flat Formik expectation
        let errors = error.response.data;
        delete Object.assign(errors, {
          ['message']: errors['messages.message_text'],
        })['messages.message_text'];
        actions.setErrors(errors);
      } else {
        setErrorMsg(error.message);
        setStatus('error');
      }
    }
  };

  useEffect(() => {
    setStatus('loading');
    const fetchListing = async () => {
      const result = await get('/account/listing/' + listingId + '.json');
      setListing(result.data);
      setStatus('loaded');
    };
    if (listingId) {
      fetchListing();
    } else {
      setStatus('loaded');
    }
  }, [listingId]);

  const selectComponent = () => {
    switch (status) {
      case 'loaded':
        const initialValues = {
          listingId: listingId,
          email: user.email,
          subject:
            listing.data && listing.data.attributes.headline
              ? `Re: ${listing.data.attributes.headline}`
              : '',
          message: '',
        };
        return (
          <Fragment>
            <Formik
              initialValues={initialValues}
              onSubmit={sendMessage}
              validationSchema={MessageSchema}
              render={(props) => {
                const { isSubmitting, errors, touched } = props;
                return (
                  <Form>
                    <p
                      style={{
                        lineHeight: '1.5',
                        color: '#4f4f4f',
                        padding: '1rem',
                      }}
                    >
                      Have a question about your listing that isn't answered in
                      our&nbsp;
                      <a
                        href="//help.listingsproject.com/hc/en-us"
                        className="button-account text-teal"
                        target="_blank"
                      >
                        Help&nbsp;Center
                      </a>
                      ? Send a message to our Customer Experience Team using the
                      form below.
                    </p>
                    <div style={{ padding: '1rem' }}>
                      {listingId && <Field type="hidden" name="listingId" />}
                      <Field
                        name="email"
                        label="Email"
                        component={ReadOnlyTextField}
                        required={true}
                      />
                      <Field
                        name="subject"
                        label="Subject"
                        component={TextField}
                        required={true}
                      />
                      <Field
                        name="message"
                        label="Message"
                        component={TextareaField}
                        required={true}
                      />
                      <button
                        className={
                          'btn ' +
                          (isSubmitting ? 'btn-grey cursor-wait' : 'btn-orange')
                        }
                        type="submit"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? 'Sending...' : 'Send Message'}
                      </button>
                    </div>
                  </Form>
                );
              }}
            />
          </Fragment>
        );
      case 'sent':
        return (
          <Fragment>
            <p style={{ lineHeight: '1.5', color: '#4f4f4f', padding: '1rem' }}>
              Your message was successfully sent!
            </p>
            <div style={{ textAlign: 'center' }}>
              <button
                onClick={closeModal}
                className="inline-block btn btn-grey no-underline"
                style={{ maxWidth: '300px' }}
              >
                Return to My Listings
              </button>
            </div>
          </Fragment>
        );
      case 'error':
        return (
          <Fragment>
            <p style={{ lineHeight: '1.5', color: '#4f4f4f', padding: '1rem' }}>
              Sorry! We ran into an error sending your message ({errorMsg}).
            </p>
            <div style={{ textAlign: 'center' }}>
              <button
                onClick={closeModal}
                className="inline-block btn btn-grey no-underline"
                style={{ maxWidth: '300px' }}
              >
                Return to My Listings
              </button>
            </div>
          </Fragment>
        );
      case 'sending':
        return <span>Sending Message...</span>;
      case 'loading':
      default:
        return <span>Loading Listing Details...</span>;
    }
  };

  return <div className="max-w-md mx-auto ">{selectComponent()}</div>;
};

export default SupportTicket;

import React, { useState, useEffect, Fragment, useRef } from 'react'
import axios from 'axios';
const get = axios.get;

const ShareLink = ({ listingId, closeModal }) => {
  const [status, setStatus] = useState(null)
  const [copied, setCopied] = useState(false)
  const [listing, setListing] = useState({})
  const inputEl = useRef(null)

  useEffect(() => {
    setStatus('loading')
    const fetchListing = async () => {
      const result = await get('/account/listing/'+ listingId +'.json')
      setListing(result.data)
      setStatus('loaded')
    }
    if (listingId) {
      fetchListing()
    }
  }, [listingId])
  const copyUrl = (e) => {
    e.preventDefault();
    inputEl.current.select();
    document.execCommand("copy");
    inputEl.current.blur();
    setCopied(true);
  }
  const selectContents = () => {
    switch (status) {
      case 'loaded':
        return (<Fragment>
          <p style={{lineHeight: '1.5', color: '#4f4f4f', padding: '1rem', textAlign: 'center'}}>
            {copied && <strong>Link successfully copied!</strong>}
          </p>
          <div className='flex'>
            <div className='flex-1'>
              <input ref={inputEl} type='text' readOnly onFocus={(event) => event.target.select()} className='rounded-lg p-3 w-full border' style={{textOverflow: 'ellipsis'}} value={`https://www.listingsproject.com${listing.data.attributes.url_path}`} />
            </div>
            <div className='flex-none'>
              <button
                className='btn btn-teal'
                style={{padding: '9px 8px', borderTopLeftRadius: 'unset', borderBottomLeftRadius: 'unset', margin: '0 0 0 -5px'}}
                onClick={copyUrl}>Copy&nbsp;Link&nbsp;</button>
            </div>
          </div>
          <p style={{lineHeight: '1.5', color: '#4f4f4f', padding: '1rem', textAlign: 'center', fontSize: '1rem'}}>
            Copy & paste this link to share.
          </p>
        </Fragment>)
      case 'loading':
      default:
        return <p style={{lineHeight: '1.5', color: '#4f4f4f', padding: '1rem'}}>Loading listing details...</p>
    }
  }
  return (
    <Fragment>{selectContents()}</Fragment>
  )
}

export default ShareLink

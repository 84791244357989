import React, { Fragment } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
const get = axios.get;

const EmailNameSchema = Yup.object().shape({
  email: Yup.string()
    .required('Email cannot be blank')
    .email('Must be a valid email address'),
  firstName: Yup.string().required('First name cannot be blank'),
  lastName: Yup.string().required('Last name cannot be blank'),
});

const EmailName = ({ email, firstName, lastName, handleEmailNameSubmit }) => {
  const userExists = async (email) => {
    return get('/users/exists', {
      params: { email: email },
    }).then((result) => {
      return result.data.exists;
    });
  };

  const doSignin = (email) => {
    let form = document.createElement('form');
    form.method = 'POST';
    form.action = '/user_sessions';
    let emailInput = document.createElement('input');
    emailInput.type = 'hidden';
    emailInput.value = email;
    emailInput.name = 'user_session[email]';
    document.body.appendChild(form);
    form.appendChild(emailInput);
    form.submit();
  };

  return (
    <div className="mt-2">
      <h1 className="text-black font-normal mb-6">
        Sign In or Join Listings Project
      </h1>

      <Formik
        initialValues={{
          email: email,
          firstName: firstName,
          lastName: lastName,
        }}
        validationSchema={EmailNameSchema}
        onSubmit={async (values, { setSubmitting }) => {
          if (await userExists(values['email'])) {
            doSignin(values['email']);
          } else {
            handleEmailNameSubmit(values);
          }
          setSubmitting(false);
        }}
      >
        {({ isSubmitting, errors, touched }) => (
          <Fragment>
            <Form>
              <div className="mb-8 max-w-sm">
                <label
                  htmlFor="email"
                  className="block font-bold mb-2 text-grey-dark"
                >
                  Email
                </label>
                <Field
                  type="email"
                  name="email"
                  className={
                    'rounded-lg p-3 w-full ' +
                    (errors.email ? 'border-2 border-red' : 'border')
                  }
                />
                {errors.email && touched.email && (
                  <div className="text-red mt-2">{errors.email}</div>
                )}
              </div>
              <div className="mb-8 max-w-sm">
                <label
                  htmlFor="firstName"
                  className="block font-bold mb-2 text-grey-dark"
                >
                  First Name
                </label>
                <Field
                  type="text"
                  name="firstName"
                  className={
                    'rounded-lg p-3 w-full ' +
                    (errors.firstName ? 'border-2 border-red' : 'border')
                  }
                />
                {errors.firstName && touched.firstName && (
                  <div className="text-red mt-2">{errors.firstName}</div>
                )}
              </div>
              <div className="mb-8 max-w-sm">
                <label
                  htmlFor="lastName"
                  className="block font-bold mb-2 text-grey-dark"
                >
                  Last Name
                </label>
                <Field
                  type="text"
                  name="lastName"
                  className={
                    'rounded-lg p-3 w-full ' +
                    (errors.lastName ? 'border-2 border-red' : 'border')
                  }
                />
                {errors.lastName && touched.lastName && (
                  <div className="text-red mt-2">{errors.lastName}</div>
                )}
              </div>
              <div className="">
                <button
                  className="btn btn-orange"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Next &gt;
                </button>
              </div>
            </Form>
          </Fragment>
        )}
      </Formik>
    </div>
  );
};

export default EmailName;

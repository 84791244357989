import React, { useState, Fragment } from 'react';
import axios from 'axios';
const post = axios.post;
import { xhrConfig } from 'form_components/apis/api';

const hideListing = async (listingId) =>
  post(`/dashboard/listings/${listingId}/hide`, {}, xhrConfig());

const HideListing = ({
  listingId,
  emailDate,
  closeModal,
  setCloseAction,
  action,
}) => {
  const [status, setStatus] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);
  const selectContents = () => {
    switch (status) {
      case 'hiding':
        return (
          <p style={{ lineHeight: '1.5', color: '#4f4f4f', padding: '1rem' }}>
            Hiding listing...
          </p>
        );
      case 'hidden':
        return (
          <Fragment>
            <p style={{ lineHeight: '1.5', color: '#4f4f4f', padding: '1rem' }}>
              Your listing was successfully hidden!
            </p>
            <div style={{ textAlign: 'center' }}>
              <button
                onClick={closeModal}
                className="inline-block btn btn-grey no-underline"
                style={{ maxWidth: '300px' }}
              >
                Return to My Listings
              </button>
            </div>
          </Fragment>
        );
      case 'error':
        return (
          <Fragment>
            <p style={{ lineHeight: '1.5', color: '#4f4f4f', padding: '1rem' }}>
              Sorry! We ran into an error hiding your listing (${errorMsg}).
            </p>
            <div style={{ textAlign: 'center' }}>
              <button
                onClick={closeModal}
                className="inline-block btn btn-grey no-underline"
                style={{ maxWidth: '300px' }}
              >
                Return to My Listings
              </button>
            </div>
          </Fragment>
        );
      default:
        return (
          <Fragment>
            <p style={{ lineHeight: '1.5', color: '#4f4f4f', padding: '1rem' }}>
              If you hide your listing, it will no longer be visible to other
              listers who may have saved your listing.
            </p>
            <div style={{ textAlign: 'center' }}>
              <br />
              <button
                onClick={handleHideListing}
                className="inline-block btn btn-teal no-underline"
                style={{ maxWidth: '300px' }}
              >
                Ok, Hide Listing
              </button>
              <br />
              <button
                onClick={closeModal}
                className="inline-block btn btn-grey no-underline"
                style={{ maxWidth: '300px' }}
              >
                Never Mind
              </button>
            </div>
          </Fragment>
        );
    }
  };

  const handleHideListing = async () => {
    setStatus('hiding');
    try {
      await hideListing(listingId);
      setCloseAction('reload');
      setStatus('hidden');
    } catch (error) {
      setCloseAction('reload');
      setStatus('error');
      setErrorMsg(error.message);
    }
  };
  return <Fragment>{selectContents()}</Fragment>;
};

export default HideListing;

import jQuery from 'jquery';
(function ($) {
  $.fn.isInViewport = function () {
    const el = $(this)[0];
    const rect = el.getBoundingClientRect();

    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || $(window).height()) &&
      rect.right <= (window.innerWidth || $(window).width())
    );
  };

  $.fn.reduce = [].reduce;

  $.fn.areElementsVisible = function (selector) {
    if ($(this) === null || $(this).length === 0) return;
    return $(this)
      .find(selector)
      .map(function () {
        return $(this).isInViewport();
      })
      .reduce(function (acc, val) {
        return acc && val;
      });
  };
})(jQuery);

import React, { Fragment, useState } from 'react';

const ClipboardButton = ({ clipText, clipExplanation }) => {
  const [copied, setCopied] = useState(false);
  return (
    <Fragment>
      {!copied && (
        <span
          className="copy-button"
          title={clipExplanation}
          onClick={(e) => {
            e.preventDefault();
            navigator.clipboard.writeText(clipText).then(
              () => {
                setCopied(true);
              },
              () => {
                e.stopPropagation();
                /* error */
              },
            );
          }}
        >
          Copy
        </span>
      )}
      {copied && <span className="copied-button">Copied</span>}
    </Fragment>
  );
};

export default ClipboardButton;


import React, { useState, Fragment } from 'react'
import axios from 'axios';
const post = axios.post;
import { xhrConfig } from 'form_components/apis/api'

const removeListing = async (listingId) => (
  post(`/dashboard/listings/${listingId}/remove`, {}, xhrConfig())
)

const RemoveListing = ({ listingId, emailDate, closeModal, setCloseAction, action }) => {
  const [status, setStatus] = useState(null)
  const [errorMsg, setErrorMsg] = useState(null)
  const selectContents = () => {
    switch (status) {
      case 'removing':
        return <p style={{lineHeight: '1.5', color: '#4f4f4f', padding: '1rem'}}>Removing listing...</p>
      case 'removed':
        return (
          <Fragment>
            <p style={{lineHeight: '1.5', color: '#4f4f4f', padding: '1rem'}}>Your listing was successfully removed!</p>
            <div style={{textAlign: 'center'}}>
              <button onClick={closeModal} className='inline-block btn btn-grey no-underline' style={{maxWidth: '300px'}}>Return to My Listings</button>
            </div>
          </Fragment>
        )
      case 'error':
        return (
          <Fragment>
            <p style={{lineHeight: '1.5', color: '#4f4f4f', padding: '1rem'}}>Sorry! We ran into an error removing your listing (${errorMsg}).</p>
            <div style={{textAlign: 'center'}}>
              <button onClick={closeModal} className='inline-block btn btn-grey no-underline' style={{maxWidth: '300px'}}>Return to My Listings</button>
            </div>
          </Fragment>
        )
      default:
        return (
          <Fragment>
            <p style={{lineHeight: '1.5', color: '#4f4f4f', padding: '1rem'}}>
              If you remove your listing, it will
              { action === 'remove-live' ? ' no longer ' : ' not ' }
              be publicly visible.
              { action === 'remove-live' ? ' We do not offer refunds ' : ' You will not be refunded automatically ' }
              (see our <a className='text-teal' href='/refund-policy' target='_blank'>refund policy</a>).
              If you wish to post your listing in the future, you
              will need to pay again to repost.
            </p>
            <div style={{textAlign: 'center'}}>
              <br />
              <button onClick={handleRemoveListing} className='inline-block btn btn-teal no-underline' style={{maxWidth: '300px'}}>Ok, Remove Listing</button>
              <br />
              <button onClick={closeModal} className='inline-block btn btn-grey no-underline' style={{maxWidth: '300px'}}>Never Mind</button>
            </div>
          </Fragment>
        )
    }
  }

  const handleRemoveListing = async () => {
    setStatus('removing')
    try {
      await removeListing(listingId)
      setCloseAction('reload')
      setStatus('removed')
    } catch (error) {
      setCloseAction('reload')
      setStatus('error')
      setErrorMsg(error.message)
    }
  }
  return (
    <Fragment>{selectContents()}</Fragment>
  )
}

export default RemoveListing

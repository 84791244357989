import React, { useState, useEffect, Fragment } from 'react';
import EmailName from 'homepage_components/join_form/email_name';
import Terms from 'homepage_components/join_form/terms';
import Subscribe from 'homepage_components/join_form/subscribe';
import Previous from 'form_components/nav/previous';
import { xhrConfig } from 'form_components/apis/api';
import axios from 'axios';
const post = axios.post;

const createUser = async ({
  email,
  firstName,
  lastName,
  acceptedTerms,
  subscribedToNewsletter,
  wantsTo,
}) => {
  const user = {
    email: email,
    first_name: firstName,
    last_name: lastName,
    agree_tos: acceptedTerms,
    subscribe_to_newsletter: subscribedToNewsletter,
    wants_to: wantsTo,
  };
  return post('/users/create_api.json', { user: user }, xhrConfig());
};

const JoinForm = ({ initialWantsTo, initialEmail }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  const [email, setEmail] = useState(initialEmail);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [subscribedToNewsletter, setSubscribedToNewsletter] = useState(null);
  const [step, setStep] = useState('emailName');
  const wantsTo = initialWantsTo;

  const handleEmailNameSubmit = ({ email, firstName, lastName }) => {
    setEmail(email);
    setFirstName(firstName);
    setLastName(lastName);
    setStep('terms');
  };
  const handleTermsSubmit = ({ acceptedTerms }) => {
    setAcceptedTerms(acceptedTerms);
    // skip subscribe step and assume true
    // setStep('subscribe');
    handleSubscribeChoice(true);
  };
  const handleSubscribeChoice = async (subscribedToNewsletter) => {
    setSubscribedToNewsletter(subscribedToNewsletter);

    const response = await createUser({
      email: email,
      firstName: firstName,
      lastName: lastName,
      acceptedTerms: acceptedTerms,
      subscribedToNewsletter: subscribedToNewsletter,
      wantsTo: wantsTo,
    });
    if (response.data.error) {
      alert('Error: ' + response.data.error);
    } else {
      let memberRedirectPath =
        '/account/membership/select?user_id=' +
        response.data.userId +
        '&wants_to=' +
        encodeURIComponent(wantsTo);
      if (subscribedToNewsletter) {
        memberRedirectPath += '&subscribed=true';
      }
      window.location = memberRedirectPath;
    }
  };

  return (
    <Fragment>
      {step == 'emailName' && (
        <EmailName
          email={email}
          firstName={firstName}
          lastName={lastName}
          handleEmailNameSubmit={handleEmailNameSubmit}
        />
      )}

      {step == 'terms' && (
        <Terms
          acceptedTerms={acceptedTerms}
          setAcceptedTerms={setAcceptedTerms}
          handleTermsSubmit={handleTermsSubmit}
        >
          <Previous
            handlePreviousClick={() => {
              setStep('emailName');
            }}
          />
        </Terms>
      )}

      {step == 'subscribe' && (
        <Subscribe
          subscribedToNewsletter={subscribedToNewsletter}
          handleSubscribeChoice={handleSubscribeChoice}
        ></Subscribe>
      )}
    </Fragment>
  );
};

export default JoinForm;

import React, { useRef, useState } from 'react';
import { Tooltip } from 'react-tooltip';
import { toggleNotifyRepost } from 'shared/helpers';
import NotifyOn from '../../../assets/images/account/notify-on.svg';
import NotifyOff from '../../../assets/images/account/notify-off.svg';

const NotificationControl = ({ notifyrepost, listing, user_id }) => {
  const [notify, setNotify] = useState(notifyrepost);
  const handleToggleNotify = async (listing_id, user_id) => {
    toggleNotifyRepost(listing_id, user_id);
    setNotify(!notify);
  };
  const tooltipRef = useRef(null);

  return (
    <span className="bookmarkTooltipContainer">
      <img
        id={`notify-${listing.id}`}
        className="block mx-3"
        src={notify ? NotifyOn : NotifyOff}
        style={{ height: '20px' }}
        onClick={(e) => {
          e.preventDefault();
          handleToggleNotify(listing.id, user_id);
        }}
      />
      <Tooltip
        ref={tooltipRef}
        anchorSelect={`#notify-${listing.id}`}
        place="bottom"
        openOnClick="true"
        border="1px solid #4f4f4f"
        className="bookmarkTooltip"
      >
        <p className="leading-tight">
          You will {notify ? '' : 'no longer '} be notified if this listing is
          reposted.
        </p>
      </Tooltip>
    </span>
  );
};

export default NotificationControl;

import React, { Fragment } from 'react';

const Subscribe = ({
  subscribedToNewsletter,
  handleSubscribeChoice,
  children,
}) => {
  return (
    <Fragment>
      <h2 className="font-bold text-2xl mb-6">
        Be the first to find out about new listings!
      </h2>

      <p className="mb-8 text-lg">
        Subscribe to our newsletter and get new listings delivered to your inbox
        every Wednesday.
      </p>
      <button
        type="button"
        className="btn btn-teal"
        onClick={() => {
          handleSubscribeChoice(true);
        }}
      >
        Subscribe
      </button>
      <button
        type="button"
        className="btn btn-grey"
        onClick={() => {
          handleSubscribeChoice(false);
        }}
      >
        Skip &gt;
      </button>
      {children}
    </Fragment>
  );
};

export default Subscribe;

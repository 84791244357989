import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'servicesform',
    'region',
    'type',
    'searchbutton',
    'searchkeyword',
  ];
  selectRegion(e) {
    e.preventDefault();
    this.regionTarget.value = e.target.dataset.option;
    this.servicesformTarget.requestSubmit();
  }
  selectCategory(e) {
    e.preventDefault();
    this.typeTarget.value = e.target.dataset.option;
    this.servicesformTarget.requestSubmit();
  }
  clickSearch(e) {
    e.preventDefault();
    const shouldClear = this.searchbuttonTarget.classList.contains(
      'search-button__clear',
    );
    if (shouldClear) {
      this.searchkeywordTarget.value = '';
    }
    this.servicesformTarget.requestSubmit();
  }
  toggleclear(e) {
    this.searchbuttonTarget.classList.replace(
      'search-button__clear',
      'search-button__search',
    );
  }
}

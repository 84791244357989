import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['sortform', 'sortorder'];
  select(e) {
    e.preventDefault();
    this.sortorderTarget.value = e.target.dataset.option;
    this.sortformTarget.requestSubmit();
  }
}

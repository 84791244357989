
import React, { Fragment } from 'react'

const DeleteDraft = ({ closeModal }) => (
  <Fragment>
    <p style={{lineHeight: '1.5', color: '#4f4f4f', padding: '1rem'}}>
      Are you sure you want to delete your draft?
    </p>
    <div style={{textAlign: 'center'}}>
      <br />
      <a href='/post/listings/delete_draft' className='inline-block btn btn-teal no-underline' style={{maxWidth: '300px'}}>Yes, Delete Draft</a>
      <br />
      <button onClick={closeModal} className='inline-block btn btn-grey no-underline' style={{maxWidth: '300px'}}>Never Mind</button>
    </div>
  </Fragment>
)

export default DeleteDraft

import React, { useState, Fragment } from 'react';
import { postForm } from 'form_components/apis/api';
import { capitalize } from '../../admin_components/admin_helpers';

const benefits = {
  supporting: {
    heading: 'All of the free member benefits plus:',
    list: [
      'Get first access to new listings',
      'Save your favorite listings',
      'Alerts when favorite listings are reposted',
      'Partner Discounts',
      'Easily cancel at any time',
    ],
    description:
      'The Supporting Membership Tier is a monthly membership plan that offers more tools to make it easier to find your place and find your people!',
    price: '$6.99/month',
  },
  free: {
    heading: 'As a free member you have access to:',
    list: [
      'Browse vetted listings',
      'Connect with Listers',
      <Fragment>
        Post listings (
        <a href="/fees" target="_blank" class="text-teal-light hover:text-teal">
          fees apply
        </a>
        )
      </Fragment>,
      'Weekly Newsletter',
      'Upgrade at any time',
    ],
    description:
      'The Free Membership Tier offers the same Listings Project experience you’ve come to know and love these past twenty years.',
    price: 'Free',
  },
};

const MembershipSelectionCard = ({
  tier,
  selectedTier,
  handleTierSelection,
}) => (
  <div
    className={`membershipSelectionCard membershipSelectionCard__${tier} mb-8`}
    onClick={(e) => handleTierSelection(tier)}
    role="button"
  >
    <h2 className="selectionHeader">
      <div className="inline-block text-teal-light">
        {capitalize(tier)} Member
      </div>
      <div
        className={`selectionCheck ${
          tier == selectedTier ? 'selectionCheck__selected' : ''
        }`}
      >
        {tier == selectedTier ? '✓' : ''}
      </div>
    </h2>
    <p className="selectionPrice">{benefits[tier].price}</p>
    <p class="leading-tight text-sm lg:text-base mb-4">
      {benefits[tier].description}
    </p>
    <p className="selectionBenefitsHeader">{benefits[tier].heading}</p>
    <ul className="membershipSelectionList ">
      {benefits[tier].list.map((benefit) => (
        <li className="membershipSelectionItem">{benefit}</li>
      ))}
    </ul>
  </div>
);

const MembershipSelection = ({ userId, email, wantsTo, subscribed }) => {
  const [tier, setTier] = useState();
  const [showSelectionReminder, setShowSelectionReminder] = useState(false);
  const redirectToConfirmation = () => {
    let redirectPath = `/users/confirm?email=${encodeURIComponent(
      email,
    )}&wants_to=${encodeURIComponent(wantsTo)}`;
    if (subscribed == 'true') {
      redirectPath += '&subscribed=true';
    }
    window.location = redirectPath;
  };

  const redirectToCheckout = () => {
    postForm('/customer_sessions/create_checkout', {
      user_id: userId,
      wants_to: wantsTo,
      is_signup: 'true',
      subscribed: subscribed,
    });
  };
  const handleJoin = (_e) => {
    if (tier == 'free') {
      redirectToConfirmation();
    } else if (tier == 'supporting') {
      redirectToCheckout();
    } else {
      setShowSelectionReminder(true);
    }
  };
  const handleTierSelection = (selection) => {
    setTier(selection);
  };

  return (
    <Fragment>
      <div className="membershipSelectionContainer">
        <MembershipSelectionCard
          tier="supporting"
          selectedTier={tier}
          handleTierSelection={handleTierSelection}
        />
        <MembershipSelectionCard
          tier="free"
          selectedTier={tier}
          handleTierSelection={handleTierSelection}
        />
      </div>
      <div
        className={`${
          showSelectionReminder ? '' : 'hidden'
        } text-center text-grey-darker mb-2`}
      >
        Please choose a membership tier above to continue.
      </div>
      <div
        className={`membershipSelectionJoin ${
          !!tier ? 'membershipSelectionJoin__active' : ''
        }`}
        onClick={handleJoin}
      >
        Join Our Community
      </div>
    </Fragment>
  );
};

export default MembershipSelection;

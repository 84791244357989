import React, { Fragment } from 'react';

const Message = ({ message, closeModal }) => (
  <Fragment>
    <p style={{ lineHeight: '1.5', color: '#4f4f4f', padding: '1rem' }}>
      {message}
    </p>
    <div style={{ textAlign: 'center' }}>
      <br />
      <button
        onClick={closeModal}
        className="inline-block btn btn-grey no-underline"
        style={{ maxWidth: '300px' }}
      >
        Ok, Got It
      </button>
    </div>
  </Fragment>
);

export default Message;

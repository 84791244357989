import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['jobform', 'region'];
  select(e) {
    e.preventDefault();
    this.regionTarget.value = e.target.dataset.option;
    this.jobformTarget.requestSubmit();
  }
}

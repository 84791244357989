import React, { useState, useRef, Fragment } from 'react';
import { truncate } from 'admin_components/admin_helpers';
import ContactedIndicator from './saved_listing_card/contacted_indicator';
import BookmarkControl from './saved_listing_card/bookmark_control';
import NotificationControl from './saved_listing_card/notification_control';

const SavedListingCard = ({
  listing,
  user_id,
  contacted,
  contactedon,
  notifyrepost,
  hideBookmarkControl = false,
}) => {
  const [removed, setRemoved] = useState(false);
  const bgClass =
    listing.subcategory == 'jobs'
      ? 'jobs'
      : listing.subcategory == 'services'
      ? 'services'
      : 're';
  if (!removed) {
    return (
      <a
        href={listing.live_view_path}
        className={`savedListingCard savedListingCard__${bgClass} bg-nearwhite `}
        target="_blank"
      >
        {' '}
        <div className="flex flex-col justify-between h-full">
          <div>
            <div
              className={`savedCardImage ${listing.expired && 'dimmed'}`}
              style={{
                backgroundImage: "url('" + listing.saved_card_image_url + "')",
              }}
            >
              <div className={`savedCardRegion savedCardRegion__${bgClass}`}>
                {listing.region_label}
              </div>
            </div>
            <div className="bg-transparent pt-3 px-6 ">
              <div className="font-bold text-grey-darkest mb-1 text-base">
                {listing.subcategory_name}
              </div>
              <h3
                title={listing.headline}
                className="text-grey-darkest mb-1 font-normal text-base"
              >
                {truncate(listing.headline, 55)}
              </h3>
            </div>
          </div>
          <div>
            <div className="flex items-center justify-end py-3 px-6">
              {contacted && (
                <ContactedIndicator
                  listing={listing}
                  contactedon={contactedon}
                />
              )}
              {!hideBookmarkControl && (
                <NotificationControl
                  notifyrepost={notifyrepost}
                  listing={listing}
                  user_id={user_id}
                />
              )}
              {!hideBookmarkControl && (
                <BookmarkControl
                  listing={listing}
                  user_id={user_id}
                  setRemoved={setRemoved}
                />
              )}
            </div>
          </div>
        </div>
      </a>
    );
  } else {
    return <span></span>;
  }
};

export default SavedListingCard;
